/*===================================================================================*/
/*	STICKY NAVIGATION
/*===================================================================================*/

$(document).ready(function () {
    if ($('.navbar .navbar-collapse').length > 0) {
        var sticky = new Waypoint.Sticky({
            element: $('.navbar .navbar-collapse')[0],
        })
    }

    if ($(window).width() <= 768) {
        $(window).scroll(function () {
            y = window.scrollY
            if (y > 120) {
                $('.sticky-bottom').addClass('stuck')
            } else {
                $('.sticky-bottom').removeClass('stuck')
            }
        })
    }
    var ps = $('.post-side')
    if (ps.length > 0 && $(window).width() > 767) {
        p = $('.posts').offset().top
        h = $('.post').height()
        $(window).scroll(function () {
            y = window.scrollY
            if (y > p - 60) {
                ps.addClass('stuck')
                d = y - p - h + 60 + 240 //header + ps height
                if (d > 0) {
                    ps.css('top', 60 - d)
                } else {
                    ps.css('top', 60)
                }
            } else {
                ps.removeClass('stuck').css('top', '')
            }
        })
    }
})

/*===================================================================================*/
/*	DROPDOWN ON HOVER (NAVIGATION)
/*===================================================================================*/
function is_touch_device() {
    return (
        $(window).width() < 1024 ||
        'ontouchstart' in window || // works on most browsers
        navigator.maxTouchPoints
    ) // works on IE10/11 and Surface
}
$(document).ready(function () {
    if (!is_touch_device()) {
        $('.js-activated')
            .dropdownHover({
                instantlyCloseOthers: false,
                delay: 0,
            })
            .dropdown()
    } else {
        $('.js-activated').on('click', function (ev) {
            ev.preventDefault()
            $(this).parent().addClass('open')
        })
    }

    //Click enabled on desktop
    $('.js-activated').on('click', function () {
        var el = $(this)
        if (el.parent().hasClass('open') && !is_touch_device()) {
            window.location.href = el.attr('href')
        }
        if (el.hasClass('clicked')) {
            el.parent().removeClass('open')
            el.removeClass('clicked')
        } else {
            el.addClass('clicked')
        }
    })
})
