//widget-totals
$(document).ready(function () {
    var el = $('#cart-modal')
    $.ajax({
        url: '/cart/widget/totals',
        dataType: 'html',
        data: null,
        success: function (data) {
            $('.loading', el).html(data)
            $('.menu-cart').html(data)
            var products = $('#cart-modal .panel-body table tbody tr').length
            if (products > 0) {
                $('.cart-dot').addClass('blink')
            }
        },
    })
})
