/*===================================================================================*/
/*	ANIMATIONS ON SCROLL
/*===================================================================================*/

var showing = false
var no_animation = $('body').hasClass('no_animation')

function showCols() {
    if (no_animation) {
        return
    }
    if (showing) {
        return
    }
    showing = true
    var waypointClass = 'main [class*="col-"]'
    var animationClass = 'fadeInUp'
    var delayTime = 0
    var waypoints = $(waypointClass)
        .not('.animated')
        .waypoint(
            function (direction) {
                var el = $(this)[0].element
                delayTime += 100
                $(this)
                    .delay(delayTime)
                    .queue(function (next) {
                        $(el).addClass('animated')
                        $(el).addClass(animationClass)
                        delayTime = 0
                        next()
                    })
            },
            {
                offset: '90%',
                triggerOnce: true,
            }
        )
    setTimeout(function () {
        showing = false
    }, 200)
}
window.showCols = showCols
