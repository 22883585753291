var $ = require('jquery')
require('bootstrap')
require('jquery.easing')
require('jquery-validation')
require('bootstrap-hover-dropdown')
require('jquery.form')
require('jquery.waypoints')
require('jquery.cookie')
require('waypoints.sticky')
require('owlcarousel')
var Isotope = require('isotope-layout')
window.viewportUnitsBuggyfill = require('viewport-units-buggyfill')

/* CSS */
import '../sass/bootstrap.min.scss'
import '../sass/app.scss'
import '../sass/fontello.scss'
import '../sass/mapglyphs.scss'

/* local libraries, [not on npm]*/
require('./showcol')
require('./animation.scroll')
require('./mask')
require('./jquery.easytabs')
require('./unveil')
require('./scrollup')
require('./owl.carousel')
require('./pushstate')
require('./algolia.search')
require('./navigation.sticky')

require('./quiz')
require('./keepright')
require('./webinars')
require('./campaigns')
require('./blog')
require('./grants')
require('./stories')
require('./cart')
require('./misc')
require('./form.validations')
require('./cart.widgets')
