// Search
if (document.sc_config != undefined && typeof document.sc_config.algolia !== 'undefined') {
    const searchUI = instantsearch({
        appId: document.sc_config.algolia.appId,
        apiKey: document.sc_config.algolia.searchApiKey,
        indexName: window.location.host.indexOf('espanol') >= 0 ? 'site_index_es' : 'site_index_en',
        urlSync: true,
    })
    searchUI.addWidget(
        instantsearch.widgets.searchBox({
            container: '#search-box',
            placeholder: 'Type in a keyword or phrase...',
        })
    )
    // initialize hits widget
    searchUI.addWidget(
        instantsearch.widgets.hits({
            container: '#hits',
            hitsPerPage: 10,
            templates: {
                empty: 'No results',
                item: '<h2><a  class="text-green" href="{{url}}">{{{_highlightResult.title.value}}}</a></h2><p class="{{source}}">Published: {{date}}</p><div class="result-content"><a href="{{url}}">{{{_highlightResult.content.value}}}</a></div> ',
            },
        })
    )
    // initialize pagination
    searchUI.addWidget(
        instantsearch.widgets.pagination({
            container: '#pagination',
            maxPages: 10,
            scrollTo: '#search-box',
        })
    )
    searchUI.start()
    var checkHighlights = function () {
        $('.ais-hits--item')
            .not('.checked')
            .each(function () {
                el = $(this).find('.result-content >  a')
                if (el.find('em').length > 0) {
                    var offset = el.find('em').position().top
                    visible = offset < 170
                    if (!visible) {
                        while (!visible) {
                            el.html('...' + el.html().substring(150))
                            visible = el.find('em').position().top < 170
                        }
                    }
                }
                $(this).addClass('checked')
            })
    }
    var currentPage = window.location.href
    var checkTimer = setInterval(function () {
        if (currentPage != window.location.href || $('.ais-hits--item.checked').length == 0) {
            $('.ais-hits--item').removeClass('checked')
            currentPage = window.location.href
            if (window.location.host.indexOf('espanol') >= 0) {
                $('.ais-hits--item:not(.checked) a').each(function () {
                    el = $(this)
                    n = el.attr('href').replace('www.startchurch', 'espanol.startchurch')
                    el.prop('href', n)
                })
            }
            checkHighlights()
        }
    }, 500)
}
