/*===================================================================================*/
/* FORM VALIDATION */
/*===================================================================================*/

var isSpanish = window.location.host.indexOf('espanol') >= 0

$(document).ready(function () {
    // Reset Password Form
    $.validator.addMethod(
        'phoneUS',
        function (phone_number, element) {
            phone_number = phone_number.replace(/\s+/g, '')
            return (
                this.optional(element) ||
                (phone_number.length > 9 &&
                    phone_number.match(/^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/))
            )
        },
        'Please enter a valid phone number.'
    )

    $('#modal-resetPassword form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        focusInvalid: false,
        rules: {
            email: {
                required: true,
                email: true,
                minlength: 2,
            },
        },
        messages: {
            email: {
                required: 'Please enter your email',
                email: 'Please enter a valid email',
                minlength: jQuery.validator.format('Email requires at least {0} characters!'),
            },
        },

        submitHandler: function (form) {
            $('#modal-resetPassword .btn-primary').html('Sending Reset request...')
            $.ajax($(form).attr('action'), {
                method: $(form).attr('method'),
                data: $(form).serialize(),
                success: function (responseText, statusText, xhr, $form) {
                    $(form).delay(1300).slideUp('fast')
                    $('#response').html(responseText).hide().delay(1300).slideDown('fast')
                    $('.show-form')
                        .off('click')
                        .on('click', function (ev) {
                            ev.preventDefault()
                            $('#modal-resetPassword .btn-primary').html('Reset')

                            $(form).slideDown('fast')
                            $(form).find('#email').val('').focus()

                            $('#response').slideUp('fast')
                        })
                },
            })
            return false
        },
    })

    //Checkout Form
    jQuery.validator.addMethod('minDate', function (value, element) {
        var now = new Date()
        var comb = parseInt(now.getFullYear() + '' + (now.getMonth() > 9 ? '' : '0') + now.getMonth())
        var myDate = parseInt(
            $('#payment_profile-expiration_year').val() + '' + $('#payment_profile-expiration_month').val()
        )
        return this.optional(element) || myDate > comb
    })
    $('#payment_profile-expiration_month, #payment_profile-expiration_year').addClass('minDate')

    checkForm = function () {
        if ($('#checkoutform').valid()) {
            $('#checkoutform #submit').prop('disabled', false)
        } else {
            $('#checkoutform #submit').prop('disabled', 'disabled')
        }
    }
    addCheckoutValidate = function () {
        $('#checkoutform input[type=text], #checkoutform select')
            .not('#payment_profile-organization_name')
            .each(function () {
                $(this).addClass('required').data('placeholder', $(this).attr('placeholder'))
            })

        $('#checkoutform').validate({
            onfocusout: function (element) {
                // "eager" validation
                this.element(element)
            },
            submitHandler: function (form) {
                $('#checkoutform #submit').attr('value', 'Processing...').attr('disabled', true)
                if ($('#checkoutform').data('valid') !== true) {
                    setTimeout(function () {
                        $('#checkoutform').data('valid', true).submit()
                    }, 1000)
                    return false
                } else {
                    $('#checkoutform').off('submit')
                    $('#checkoutform #submit').prop('disabled', false)
                    setTimeout(function () {
                        $('#checkoutform #submit').click().prop('disabled', true)
                    }, 200)
                    return true
                }
            },
        })
    }
    addCheckoutValidate()

    //sets up the validator

    //Reset Passowrd
    $('#reset-password').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        focusInvalid: false,
        rules: {
            password: {
                required: true,
            },
            retype_password: {
                required: true,
                equalTo: '#password',
            },
        },
        messages: {
            password: {
                required: 'Please enter your password',
            },
            retype_password: {
                required: 'Please  re enter your password!',
                equalTo: 'Your confirmation does not match your password',
            },
        },
    })

    // Login Form
    $('#login-form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        focusInvalid: false,
        rules: {
            identity: {
                required: true,
                minlength: 2,
            },
            password: {
                required: true,
                minlength: 2,
            },
        },
        messages: {
            identity: {
                required: 'Please enter your username or your email',
                minlength: jQuery.validator.format('Username requires at least {0} characters!'),
            },
            password: {
                required: 'Please enter your password!',
                minlength: jQuery.validator.format('Password requires at least {0} characters!'),
            },
        },
    })

    $('.conference-contact-form').each(function () {
        $(this).validate({
            errorPlacement: function (error, element) {
                $(element).attr({
                    placeholder: error.html(),
                })
            },
            focusInvalid: false,
            rules: {
                name: {
                    required: true,
                    minlength: 2,
                },
                email: {
                    required: true,
                    email: true,
                },
                phone: {
                    required: true,
                    minlength: 10,
                },
            },
            messages: {
                name: {
                    required: 'Please enter your name!',
                    minlength: jQuery.validator.format('Name requires at least {0} characters!'),
                },
                phone: {
                    required: 'Please enter your phone!',
                    minlength: jQuery.validator.format('Phone requires at least {0} characters!'),
                },
                email: {
                    required: 'Please enter your email!',
                    email: 'Please enter a valid email!',
                },
            },
            submitHandler: function (form) {
                var btn = $(form).find('.btn-orange')
                btn.html(btn.data('progress'))
                $.ajax($(form).attr('action'), {
                    data: $(form).serialize(),
                    success: function (responseText, statusText, xhr, $form) {
                        $(form).parents('.option-2').delay(1300).slideUp('fast')
                        $(form).parents('.multiple-options').find('.response').hide().delay(1300).slideDown('fast')
                    },
                })
                return false
            },
        })
    })

    $('#conference-contact-form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        focusInvalid: false,
        rules: {
            name: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                email: true,
            },
            phone: {
                required: true,
                minlength: 10,
            },
        },
        messages: {
            name: {
                required: 'Please enter your name!',
                minlength: jQuery.validator.format('Name requires at least {0} characters!'),
            },
            phone: {
                required: 'Please enter your phone!',
                minlength: jQuery.validator.format('Phone requires at least {0} characters!'),
            },
            email: {
                required: 'Please enter your email!',
                email: 'Please enter a valid email!',
            },
        },
        submitHandler: function (form) {
            $('#conference-contact-form .btn-submit').html('Sending...')
            $.ajax($(form).attr('action'), {
                data: $(form).serialize(),
                method: $(form).attr('method'),
                success: function (responseText, statusText, xhr, $form) {
                    $(form).delay(1300).slideUp('fast')
                    $('#response').hide().delay(1300).slideDown('fast')
                },
            })
            return false
        },
    })

    // Contact Form
    $('#contact_form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },

        focusInvalid: false,

        rules: {
            nameFirst: {
                required: true,
                minlength: 2,
            },
            nameLast: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                email: true,
            },
            phone: {
                required: true,
                minlength: 10,
            },
            comment: {
                required: true,
                minlength: 10,
            },
        },

        messages: {
            nameFirst: {
                required: 'Please enter your first name!',
                minlength: jQuery.validator.format('First Name requires at least {0} characters!'),
            },
            nameLast: {
                required: 'Please enter your last name!',
                minlength: jQuery.validator.format('Last Name requires at least {0} characters!'),
            },
            phone: {
                required: 'Please enter your phone!',
                minlength: jQuery.validator.format('Phone requires at least {0} characters!'),
            },
            email: {
                required: 'Please enter your email!',
                email: 'Please enter a valid email!',
            },
            comment: {
                required: 'Please enter a message!',
                minlength: jQuery.validator.format('Message requires at least {0} characters!'),
            },
        },
        submitHandler: function (form) {
            var btn = $('#contact_form .btn-submit')
            btn.html(btn.data('sending') + '...')
            $.ajax($(form).attr('action'), {
                data: $(form).serialize(),
                method: $(form).attr('method'),
                success: function (responseText, statusText, xhr, $form) {
                    $(form).delay(1300).slideUp('fast')
                    $('#response').html(btn.data('success')).hide().delay(1300).slideDown('fast')
                },
            })
            return false
        },
    })

    $('#registration-form').validate({
        errorPlacement: function (error, element) {
            if (element[0].type == 'checkbox') {
                $(element).parents('label').addClass('label-error')
            } else {
                $(element).attr({
                    placeholder: error.html(),
                })
            }
        },
        onclick: function (element, event) {
            if ($(element).is(':checked')) {
                $(element).parents('label').removeClass('label-error')
            }
        },
        focusInvalid: false,
        submitHandler: function (form) {
            grecaptcha.execute()
            return false
        },
        rules: {
            'fos_user_registration_form[firstName]': {
                required: true,
                minlength: 2,
            },
            'fos_user_registration_form[lastName]': {
                required: true,
                minlength: 2,
            },
            'fos_user_registration_form[organization]': {
                required: true,
            },
            'fos_user_registration_form[email]': {
                required: true,
                email: true,
            },
            'fos_user_registration_form[phone]': {
                required: true,
                minlength: 10,
            },
            'fos_user_registration_form[plainPassword][first]': {
                required: true,
            },
            'fos_user_registration_form[plainPassword][second]': {
                required: true,
                equalTo: '#fos_user_registration_form_plainPassword_first',
            },
            terms: {
                required: true,
            },
        },

        messages: {
            'fos_user_registration_form[firstName]': {
                required: 'Your first name is required.',
            },
            'fos_user_registration_form[lastName]': {
                required: 'Your last name is required.',
            },
            'fos_user_registration_form[organization]': {
                required: 'Your organization name is required.',
            },
            'fos_user_registration_form[email]': {
                required: 'Your email address is required.',
                email: 'Please enter a valid email address.',
            },
            'fos_user_registration_form[phone]': {
                required: 'Your phone number is required.',
                phone: 'Please enter a valid phone number.',
            },
            'fos_user_registration_form[plainPassword][first]': {
                required: 'Your password is required.',
            },
            'fos_user_registration_form[plainPassword][second]': {
                required: 'Your password confirmation is required.',
                equalTo: 'Your confirmation does not match your password.',
            },
            terms: {
                required: 'Please agree.',
            },
        },
    })

    //Refer Email

    $('#ReferralEmail').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        focusInvalid: false,
        rules: {
            fromName: {
                required: true,
                minlength: 2,
            },
            toName: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                email: true,
            },
        },
        messages: {
            fromName: {
                required: 'Please enter your name',
                minlength: jQuery.validator.format('Your Name requires at least {0} characters!'),
            },
            toName: {
                required: "Please enter your friend's name",
                minlength: jQuery.validator.format("Your Friend's Name requires at least {0} characters!"),
            },
            email: {
                required: "Please enter your  friend's email",
                email: 'Please enter a valid email!',
            },
        },
    })

    // Example
    $('#commentform').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },

        focusInvalid: false,

        rules: {
            name: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                email: true,
            },
            message: {
                required: true,
                minlength: 10,
            },
        },

        messages: {
            name: {
                required: 'Please enter your name!',
                minlength: jQuery.validator.format('Name requires at least {0} characters!'),
            },
            email: {
                required: 'Please enter your email!',
                email: 'Please enter a valid email!',
            },
            message: {
                required: 'Please enter a message!',
                minlength: jQuery.validator.format('Message requires at least {0} characters!'),
            },
        },

        submitHandler: function (form) {
            $('#contactform .btn-submit').html('Sending message ...')
            $('#commentform .btn-submit').html('Sending comment ...')
            $.ajax($(form).attr('action'), {
                data: $(form).serialize(),
                method: $(form).attr('method'),
                success: function (responseText, statusText, xhr, $form) {
                    $(form).delay(1300).slideUp('fast')
                    $('#response').html(responseText).hide().delay(1300).slideDown('fast')
                },
            })
            return false
        },
    })
})

//Lead Capture Form
$('#contact-campaigns .btn-reset').on('click', function (ev) {
    ev.preventDefault()
    $('#campaigns-contact-form')[0].reset()
    $('.lead-action').removeClass('hidden')
    $('.lead-success').addClass('hidden')
})
jQuery.validator.addMethod(
    'phoneMasked',
    function (value, element) {
        return value.replace(/[^\d]/g, '').length == 10
    },
    isSpanish ? 'Por favor, introduzca un número de teléfono válido' : 'Please enter valid phone number'
)

$('#campaigns-contact-form, .campaigns-contact-form').each(function () {
    $(this).validate({
        errorPlacement: function (error, element) {
            $(element).parents('.controls').find('label.text-danger').remove()

            if ($(element).attr('type') == 'radio') {
                $(element)
                    .parents('.controls')
                    .find('label')
                    .first()
                    .after('<label class="text-danger">' + error.html() + '</label>')
            } else {
                $(element).attr({
                    placeholder: error.html(),
                })
            }
        },
        submitHandler: function (form) {
            var inputs = $(':input.lead-input, textarea.lead-input', form)
            $('input[type="submit"], button[type="submit"]', form).addClass('disabled')

            var extraFieldClass = $('.extra-field')
            var extraFields = $(form).find(extraFieldClass)
            var data = {}
            var extraFieldsData = []

            inputs.each(function () {
                data[this.name] = $(this).val()
            })
            extraFields.each(function () {
                var currentFieldValue = $(this).is('select') ? $(this).val() : $(this).text()
                extraFieldName = $(this).data('name')
                data[extraFieldName] = currentFieldValue
                if (extraFieldName.indexOf('question_') == -1) {
                    extraFieldsData.push(extraFieldName + '=' + currentFieldValue)
                }
            })

            $.post('/api/campaign-log', JSON.stringify(data), function () {
                var redirect = $('.field-redirect', form)
                if (redirect.length > 0) {
                    var targetUrl = redirect.val()
                    if (redirect.hasClass('include-answers-in-parameters')) {
                        targetUrl = targetUrl + '?' + extraFieldsData.join('&')
                    }
                    window.location.href = targetUrl
                } else {
                    $('.lead-action').addClass('hidden')
                    $('.lead-success').removeClass('hidden')
                }

                $('input[type="submit"], button[type="submit"]', form).removeClass('disabled')
                gtag('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: window.document.getElementById('campaigns-contact-form').children.campaign_code.value,
                    value: window.location.pathname,
                })
                window.uetq = window.uetq || []
                window.uetq.push('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: window.document.getElementById('campaigns-contact-form').children.campaign_code.value,
                })
            })
            return false
        },
        focusInvalid: false,
        rules: {
            first_name: {
                required: true,
                minlength: 2,
            },
            last_name: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                email: true,
            },
            phone: {
                required: true,
                minlength: 10,
                maxlength: 14,
                phoneMasked: true,
            },
        },
        messages: {
            first_name: {
                required: isSpanish ? 'Por favor, introduzca su nombre' : 'Please enter your first name',
                minlength: jQuery.validator.format(
                    isSpanish
                        ? 'Nombre requiere al menos {0} caracteres'
                        : 'First Name requires at least {0} characters'
                ),
            },
            last_name: {
                required: isSpanish ? 'Por favor, introduzca su apellido' : 'Please enter your last name',
                minlength: jQuery.validator.format(
                    isSpanish
                        ? 'Apellido requiere al menos {0} caracteres'
                        : 'Last Name requires at least {0} characters'
                ),
            },
            phone: {
                required: isSpanish ? 'Por favor, introduzca su teléfono' : 'Please enter your phone',
                minlength: jQuery.validator.format(
                    isSpanish ? 'Teléfono requiere al menos {0} caracteres' : 'Phone requires at least {0} characters'
                ),
            },
            zip: {
                required: isSpanish ? 'Por favor, introduzca su código postal' : 'Please enter your zip code',
                minlength: jQuery.validator.format(
                    isSpanish
                        ? 'Código postal requiere al menos {0} caracteres'
                        : 'Zip codes requires at least {0} characters'
                ),
            },
            email: {
                required: isSpanish ? 'Por favor, introduzca su email' : 'Please enter your email',
                email: isSpanish ? 'Por favor, introduzca un email válido' : 'Please enter a valid email',
            },
        },
    })
})

// Exit Intent form handling
$(document).ready(function () {
    $('.modal-exit form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        submitHandler: function () {
            $('.modal-exit #submit-exit').html('Submitting...').addClass('disabled')
            var $inputs = $('.modal-exit .lead-form :input.lead-input')
            var data = {}
            $inputs.each(function () {
                data[this.name] = $(this).val()
            })
            var campaign_code = data.campaign_code
            $.post('/api/campaign-log', JSON.stringify(data), function () {
                $('.modal-exit').modal('hide')
                $('#modal-exit-thankyou, .modal-exit-thankyou').modal('show')
                $('.modal-exit #submit-exit').removeClass('disabled')
                if (
                    $('.modal-exit').data('download-link') != undefined &&
                    $('.modal-exit').data('download-link') !== 'undefined'
                ) {
                    window.location.href = $('.modal-exit').data('download-link')
                }

                gtag('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: campaign_code,
                    value: 'modal_hover',
                })
                window.uetq = window.uetq || []
                window.uetq.push('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: campaign_code,
                })
            })
            return false
        },
        focusInvalid: false,
        rules: {
            email: {
                required: true,
                email: true,
            },
            first_name: {
                required: true,
            },
        },

        messages: {
            email: {
                required: isSpanish ? 'Por favor escriba su nombre' : 'Please enter your email',
                email: 'Please enter a valid email',
            },
            first_name: {
                required: isSpanish ? 'Por favor escriba su correo electrónico' : 'Please enter your name',
            },
        },
    })
})

// Download Ebook form handling
$(document).ready(function () {
    $('#modal-ebook form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        submitHandler: function () {
            $('#submit-ebook').html('Submitting...')
            var $inputs = $('#modal-ebook .lead-form :input.lead-input')
            var data = {}
            $inputs.each(function () {
                data[this.name] = $(this).val()
            })
            var campaign_code = data.campaign_code
            $.post('/api/campaign-log', JSON.stringify(data), function () {
                $('#modal-ebook').modal('hide')
                $('#modal-ebook-thankyou').modal('show')
                window.location.href = $('#modal-ebook').data('download-link')
                gtag('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: campaign_code,
                    value: 'modal_hover',
                })
                window.uetq = window.uetq || []
                window.uetq.push('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: campaign_code,
                })
            })
            return false
        },
        focusInvalid: false,
        rules: {
            email: {
                required: true,
                email: true,
            },
            first_name: {
                required: true,
            },
            phone: {
                required: true,
                minlength: 10,
            },
        },

        messages: {
            email: {
                required: 'Please enter your email',
                email: 'Please enter a valid email',
            },
            first_name: {
                required: 'Please enter your name',
            },
            phone: {
                required: 'Please enter your phone number',
            },
        },
    })
})

// Takeover(Hover) form handling
$(document).ready(function () {
    $('#submit-hover').val($('#hover-content').data('download-title'))

    $('#modal-hover form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },

        submitHandler: function () {
            $('#submit-hover').html('Submitting...').addClass('disabled')
            $.cookie($('#modal-hover').data('code') + 'ShowSignup', 0, {
                expires: 365,
                path: '/',
            })

            var $inputs = $('.lead-form :input.lead-input')
            var data = {}
            $inputs.each(function () {
                data[this.name] = $(this).val()
            })
            var hover_campaign_code = data.campaign_code
            $.post('/api/campaign-log', JSON.stringify(data), function () {
                $('#submit-hover').addClass('hidden').removeClass('disabled')
                $('#hover-text').addClass('hidden')
                $('#hover-success').removeClass('hidden')
                window.location.href = $('#hover-content').data('download-link')
                gtag('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: hover_campaign_code,
                    value: 'modal_hover',
                })
                window.uetq = window.uetq || []
                window.uetq.push('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: hover_campaign_code,
                })
            })
            return false
        },

        focusInvalid: false,

        rules: {
            email: {
                required: true,
                email: true,
            },
            first_name: {
                required: true,
            },
            phone: {
                required: true,
                minlength: 10,
            },
        },

        messages: {
            email: {
                required: 'Please enter your email',
                email: 'Please enter a valid email',
            },
            first_name: {
                required: 'Please enter your name',
            },
            phone: {
                required: 'Please enter your phone number',
            },
        },
    })
})

$('#RefundForm').validate({
    rules: {
        email: {
            required: true,
            email: true,
            minlength: 2,
        },
        phone: {
            required: true,
            minlength: 8,
        },
    },
    messages: {
        first_name: {
            required: 'Please enter your first name',
        },
        last_name: {
            required: 'Please enter your last name',
        },
        phone: {
            required: 'Please enter your phone number',
        },
        email: {
            required: 'Please enter your email',
            email: 'Please enter a valid email',
        },
    },
})

// Blog Subscription form handling
$(document).ready(function () {
    $('#blog-subscribe form').validate({
        errorPlacement: function (error, element) {
            $(element).attr({
                placeholder: error.html(),
            })
        },
        submitHandler: function () {
            if ($('#submit-exit').hasClass('disabled')) return
            $('#submit-exit').html('Submitting...').prop('disabled', true).addClass('disabled')

            var $inputs = $('#blog-subscribe .lead-form :input.lead-input')
            var data = {}
            $inputs.each(function () {
                data[this.name] = $(this).val()
            })
            var campaign_code = data.campaign_code
            $.post('/api/campaign-log', JSON.stringify(data), function () {
                $('#submit-exit').removeClass('disabled').prop('disabled', false)
                $('#blog-subscribe').modal('hide')
                $('#blog-subscribe-thankyou').modal('show')
                gtag('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: campaign_code,
                    value: 'modal_hover',
                })
                window.uetq = window.uetq || []
                window.uetq.push('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label: campaign_code,
                })
            })
            return false
        },
        focusInvalid: false,
        rules: {
            email: {
                required: true,
                email: true,
            },
            first_name: {
                required: true,
            },
            phone: {
                required: true,
                minlength: 10,
            },
        },

        messages: {
            email: {
                required: 'Please enter your email',
                email: 'Please enter a valid email',
            },
            first_name: {
                required: 'Please enter your name',
            },
            phone: {
                required: 'Please enter your phone number',
            },
        },
    })
})

// Free Video Course form handling
$(document).ready(function () {
    const registered = $.cookie('fvc_registered')
    if (registered) {
        $('#video-course-contact-form').parent('.lead-action').addClass('hidden')
        $('#video-course-contact-form')
            .parent('.lead-action')
            .parent('.row')
            .find('.lead-success')
            .removeClass('hidden')
        return
    }

    $('#video-course-contact-form').validate({
        errorPlacement: function (error, element) {
            $(element).parents('.controls').find('label.text-danger').remove()

            if ($(element).attr('type') === 'radio') {
                $(element)
                    .parents('.controls')
                    .find('label')
                    .first()
                    .after('<label class="text-danger">' + error.html() + '</label>')
            } else {
                $(element).attr({
                    placeholder: error.html(),
                })
            }
        },
        submitHandler: function () {
            const inputs = $('#video-course-contact-form :input.lead-input, textarea.lead-input')
            $('input[type="submit"], button[type="submit"]', '#video-course-contact-form ').addClass('disabled')

            const extraFieldClass = $('.extra-field')
            const extraFields = $('#video-course-contact-form').find(extraFieldClass)
            const data = {}

            inputs.each(function () {
                data[this.name] = $(this).val()
            })

            extraFields.each(function () {
                extraFieldName = $(this).data('name')
                data[extraFieldName] = $(this).text()
            })

            $.post('/api/campaign-log', JSON.stringify(data), function (data) {
                if (data.success) $.cookie('fvc_registered', true, { expires: 7 })

                $('.lead-action').addClass('hidden')
                $('.lead-success').removeClass('hidden')
                $('input[type="submit"], button[type="submit"]', '#video-course-contact-form').removeClass('disabled')

                gtag('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label:
                        window.document.getElementById('video-course-contact-form').children.campaign_code.value,
                    value: window.location.pathname,
                })

                window.uetq = window.uetq || []
                window.uetq.push('event', 'sign_up', {
                    event_category: 'engagement',
                    event_label:
                        window.document.getElementById('video-course-contact-form').children.campaign_code.value,
                })
            })
            return false
        },
        focusInvalid: false,
        rules: {
            first_name: {
                required: true,
                minlength: 2,
            },
            last_name: {
                required: true,
                minlength: 2,
            },
            email: {
                required: true,
                email: true,
            },
            phone: {
                required: true,
                minlength: 10,
            },
        },
        messages: {
            first_name: {
                required: 'Please enter your first name!',
                minlength: jQuery.validator.format('First Name requires at least {0} characters!'),
            },
            last_name: {
                required: 'Please enter your last name!',
                minlength: jQuery.validator.format('Last Name requires at least {0} characters!'),
            },
            phone: {
                required: 'Please enter your phone!',
                minlength: jQuery.validator.format('Phone requires at least {0} characters!'),
            },
            email: {
                required: 'Please enter your email!',
                email: 'Please enter a valid email!',
            },
        },
    })
})

$.validator.messages.required = isSpanish ? 'Este campo es obligatorio' : 'This field is required'
