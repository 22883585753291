/*===================================================================================*/
/*	Webinars
/*===================================================================================*/

$(document).ready(function () {
    var $container = $('.items')
    var iso
    $('.items').on('click', '.item', function () {
        $('#webinars video').each(function () {
            //stop playing
            $(this)[0].pause()
            $(this).parents('.item').removeClass('active')
        })

        $(this).addClass('active')
        //open modal
        $('#modal-video-' + $(this).data('id')).modal('show')
        $('#modal-video-' + $(this).data('id')).on('hide.bs.modal', function (ev) {
            $(this).find('video')[0].pause()
        })
    })
})
