var Isotope = require('isotope-layout')
import { showCols } from './animation.scroll'
/*===================================================================================*/
/* Stories  */
/*===================================================================================*/
var inStories = $('#stories').length > 0
window.newIso = null
if (inStories) {
    window.setTimeout(function () {
        var f = window.location.hash == '' ? '.item' : '.' + window.location.hash.replace('#', '')
        window.newIso = new Isotope('.items', {
            itemSelector: '.item',
            filter: f,
        })
    }, 1000)

    var resizeTimer

    function resizeFunction() {
        var f = window.location.hash == '' ? '.item' : '.' + window.location.hash.replace('#', '')
        if (window.newIso != undefined) {
            window.newIso.destroy()
        }
        window.newIso = Isotope('.items', {
            itemSelector: '.item',
            filter: f,
        })
    }

    $(window).resize(function () {
        clearTimeout(resizeTimer)
        resizeTimer = setTimeout(resizeFunction, 500)
    })
    $('a.panel-toggle.collapsed').click(function () {
        clearTimeout(resizeTimer)
        resizeTimer = setTimeout(resizeFunction, 500)
    })
    $('.portfolio .filter li a').click(function () {
        $('.portfolio .filter li a').removeClass('active')
        $(this).addClass('active')
        var selector = $(this).attr('data-filter')
        window.newIso.destroy()
        window.newIso = new Isotope('.items', {
            filter: selector,
        })
        window.showCols()
        return false
    })
}
