var Isotope = require('isotope-layout')

/*===================================================================================*/
/*  INIT SUBSCRIBE MODAL
/*===================================================================================*/
$('.subscribe-btn').on('click', function () {
    $('#blog-subscribe').modal('show')
})

/*===================================================================================*/
/*	TEEWTABLE  short url detection and update - @TODO move to admin....
/*===================================================================================*/
if ($('.tweetable, .es-tweetable, .fb-share-button, .popup.twitter').length > 0) {
    var api_url = 'https://api-ssl.bitly.com',
        api_login = document.sc_config ? document.sc_config.bitly_api_login : '',
        api_key = document.sc_config ? document.sc_config.bitly_api_key : ''
    var long_url = window.location.href.split('?')[0]
    $.getJSON(
        api_url +
            '/v3/shorten?longUrl=' +
            encodeURIComponent(long_url) +
            '&login=' +
            api_login +
            '&apiKey=' +
            api_key +
            '&callback=?',
        function (response) {
            var url = response.data.url
            $('.fb-share-button, .popup.twitter').data('location', url)
            $('.tweetable').each(function (i) {
                var e = $(this),
                    t = e.text()
                e.wrap(
                    '<a target="_blank" href="https://twitter.com/share?url=' +
                        url +
                        '&text=' +
                        encodeURI(t) +
                        '" class="tweetable-wrapper" id="tweetable-' +
                        i +
                        '"></a>'
                )
                e.parent().append(
                    '<span class="tweet"> &mdash;	 Click to tweet this &mdash;	 <i class="icon-s-twitter"></i></span>'
                )
            })
            $('.es-tweetable').each(function (i) {
                var e = $(this),
                    t = e.text()
                e.wrap(
                    '<a target="_blank" href="https://twitter.com/share?url=' +
                        url +
                        '&text=' +
                        encodeURI(t) +
                        '" class="tweetable-wrapper" id="tweetable-' +
                        i +
                        '"></a>'
                )
                e.parent().append(
                    '<span class="tweet"> &mdash;	 Haga clic para twittear esto &mdash;	 <i class="icon-s-twitter"></i></span>'
                )
            })
        }
    )
}

// Back button
$('.back-button').on('click', function (ev) {
    ev.preventDefault()
    if (document.referrer == '') {
        window.location.href = $(this).attr('href')
    } else {
        history.back()
    }
})
$(window).on('load', function () {
    if ($('.posts .post').length > 2) {
        new Isotope('.posts', {
            itemSelector: '.post',
        })
    }
})
// Sharing
$(document).ready(function () {
    $('.popup.twitter').click(function (event) {
        var width = 575,
            height = 400,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left,
            location = $(this).data('location') != '' ? $(this).data('location') : window.location.href,
            title = $(this).data('title') != '' ? $(this).data('title') : encodeURI(document.title.replace(/;/g, ''))

        window.open('https://twitter.com/share?text=' + title + '&url=' + location, 'twitter', opts)
        return false
    })
    $('.fb-share-button').on('click', function (ev) {
        ev.preventDefault()
        var width = 575,
            height = 400,
            left = ($(window).width() - width) / 2,
            top = ($(window).height() - height) / 2,
            opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left,
            location = window.location.href

        window.open('http://www.facebook.com/sharer/sharer.php?u=' + location, 'facebook', opts)
    })
})

/**
 * blog vote
 */
$(document).ready(function() {
    $('.blog_vote .btn').each(function() {
        const [url, method] = getUrlMethod($(this))
        
        const vote = localStorage.getItem(url)
        if(vote) {
            if($(this).val() == vote) {
                setVoteButtonAsClicked($(this))
            }
            showFeedback()
        }
    })

    $('.blog_vote .btn').click(function(e) {
        const [url,method] = getUrlMethod($(this))

        const vote = $(this).val()
        setVoteButtonAsClicked($(this))

        localStorage.setItem(url, vote)

        $.ajax({
            url,
            method,
            data: { vote }
        }).complete(() => {
            showFeedback()
        })
    })

    function setVoteButtonAsClicked(btn) {
        btn.attr('clicked', true)
    }

    function getUrlMethod(btn) {
        const form = btn.closest('form')
        const url = form.attr('action')
        const method = form.attr('method')
        return [url, method]
    }

    function showFeedback() {
        $('.blog_vote .complete').show()
        $('.blog_vote .btn').prop('disabled', true)
    }
})

/**
 * blog track view
 */
$(document).ready(function() {
    $('.blog_track_views').each(function() {
        const postId = $(this).data('post-id')
        if(!postId) {
            return;
        }

        const key = 'view-article-' + postId
        const hasViewed = localStorage.getItem(key)
        if(hasViewed) {
            return;
        }

        localStorage.setItem(key, true)

        $.ajax({
            url: '/blog/view-count/' + postId,
            method: 'POST',
            data: null,
        })
    })
})
