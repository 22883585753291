/*===================================================================================*/
/*	KEEPRIGHT
/*===================================================================================*/
$(document).ready(function () {
    if ($("[data-shortname='keepright-ministry'],[data-shortname='keepright-charity']").length > 0) {
        $('#calculator_submit').click(function () {
            var income, assets
            income = $('#income')
            assets = $('#assets')
            $('#prices').children().fadeOut()
            $('#prices')
                .children()
                .promise()
                .done(function () {
                    if (assets.val() == 2 || income.val() == 3) {
                        $('.opt-1').hide()
                        $('.opt-2').hide()
                        $('.opt-3').show()
                        $('#form_results_1').fadeIn()
                    } else if (assets.val() == 1 && income.val() == 1) {
                        $('.opt-1').show()
                        $('.opt-2').hide()
                        $('.opt-3').hide()
                        p
                        $('#form_results_1').fadeIn()
                    } else if (assets.val() == 1 && income.val() == 2) {
                        $('.opt-1').hide()
                        $('.opt-2').show()
                        $('.opt-3').hide()
                        $('#form_results_1').fadeIn()
                    } else {
                        $('#form_results_0').fadeIn()
                    }
                })
        })

        var package1, package2, toggleHidden
        package1 = $('#package1')
        package2 = $('#package2')
        toggleHidden = function () {
            if (package1.hasClass('active')) {
                $('.grp-2').addClass('hidden-xs')
                $('.grp-1').removeClass('hidden-xs')
            } else if (package2.hasClass('active')) {
                $('.grp-1').addClass('hidden-xs')
                $('.grp-2').removeClass('hidden-xs')
            }
        }
        toggleHidden()
        package1.click(function () {
            $(this).siblings().removeClass('active')
            $(this).addClass('active')
            toggleHidden()
        })
        package2.click(function () {
            $(this).siblings().removeClass('active')
            $(this).addClass('active')
            toggleHidden()
        })
    }
    if ($("[data-shortname='keepright-church']").length > 0) {
        var package1, package2, toggleHidden
        package1 = $('#package1')
        package2 = $('#package2')
        toggleHidden = function () {
            if (package1.hasClass('active')) {
                $('.grp-2').addClass('hidden-xs')
                $('.grp-1').removeClass('hidden-xs')
            } else if (package2.hasClass('active')) {
                $('.grp-1').addClass('hidden-xs')
                $('.grp-2').removeClass('hidden-xs')
            }
        }
        toggleHidden()
        package1.click(function () {
            $(this).siblings().removeClass('active')
            $(this).addClass('active')
            toggleHidden()
        })
        package2.click(function () {
            $(this).siblings().removeClass('active')
            $(this).addClass('active')
            toggleHidden()
        })
    }
})
