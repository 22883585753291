/*===================================================================================*/
/*	History pushState fix to trigger analytics
/*===================================================================================*/
var pushState = history.pushState
history.pushState = function () {
    pushState.apply(history, arguments)
    if (typeof ga != 'undefined') {
        ga('send', 'pageview', arguments[2])
    }
}
