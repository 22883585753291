$('.buy-cloud-product').click(function (e) {
    el = $(this)
    $.ajax('/api/cart/add-cloud-product', {
        data: JSON.stringify({
            overwrite: true,
            product_id: $(this).data('product-id'),
            auto_renew_type: 1,
        }),
        contentType: 'application/json',
        type: 'POST',
        success: function (data, status) {
            if (el.data('promo') !== undefined && el.data('promo') != '') {
                window.location = '/checkout/index/system?promo=' + el.data('promo')
            } else {
                window.location = '/checkout/index/system'
            }
        },
    })
    return false
})

$('.buy-product').click(function (e) {
    $.ajax('/api/cart/add-product', {
        data: JSON.stringify({
            overwrite: true,
            product_id: $(this).data('product-id'),
            system: $(this).data('system'),
        }),
        contentType: 'application/json',
        type: 'POST',
        success: function (data, status) {
            window.location = '/checkout/index/system'
        },
    })
    return false
})
