/*===================================================================================*/
/*	SCROLL SPY
/*===================================================================================*/

$(document).ready(function () {
    $('body').scrollspy({
        target: '.navbar-collapse',
        offset: 50,
    })
})

/*===================================================================================*/
/*	ANIMATED / SMOOTH SCROLL TO ANCHOR
/*===================================================================================*/

export function scrollMe(target, callback) {
    if ($(window).width() > 1024) {
        var navbarHeight = 90
    } else {
        var navbarHeight = 0
    }

    if ($(this).attr('data-anchor-offset') !== undefined) {
        var anchorOffset = $(this).attr('data-anchor-offset')
    } else {
        var anchorOffset = 0
    }
    $('html, body').animate(
        {
            scrollTop: target.offset().top - navbarHeight - anchorOffset + 'px',
        },
        {
            duration: 1000,
            easing: 'easeInOutCubic',
            complete: callback,
        }
    )
}

$(document).ready(function () {
    if (window.location.hash) {
        try {
            if ($(window.location.hash).length > 0) {
                setTimeout(function () {
                    scrollMe($(window.location.hash), null)
                }, 500)
            }
        } catch (e) {
            // On admin site, the hash is a route path which makes this jQuery
            // code throw an exception and stop the JS execution.
        }
    }
    $(document).on('click', 'a.scroll-to', function (ev) {
        ev.preventDefault()
        scrollMe($($(this).attr('href'), null))
        return false
    })
})
