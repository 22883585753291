/*===================================================================================*/
/*	QUIZ RESULTS
/*===================================================================================*/
$.fn.stars = function () {
    return $(this).each(function () {
        // Get the value
        var val = parseFloat($(this).html())
        // Make sure that the value is in 0 - 5 range, multiply to get width
        if (val < 3) {
            $(this).addClass('red')
        } else if (val < 4) {
            $(this).addClass('yellow')
        }
        var size = Math.max(0, Math.min(5, val)) * 135
        // Create stars holder
        var $span = $('<span />').animate({ width: size }, { duration: 2000, easing: 'easeOutBounce' })
        // Replace the numerical value with stars
        $(this).html($span)
    })
}
function styleCorrect(correct) {
    $.each(correct, function (index, value) {
        $('#' + value)
            .removeClass('wrong-answer')
            .find('span')
            .addClass('hidden')
        $('#' + value)
            .find('h3')
            .removeClass('wrong-answer')
        $('#' + value)
            .find('.explanation')
            .addClass('hidden')
    })
}

$(function () {
    if (typeof correct !== 'undefined') {
        styleCorrect(correct)
        $('span.stars').stars()
    }
})
