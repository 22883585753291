import exitIntent from 'exit-intent'

/*===================================================================================*/
/* TABS */
/*===================================================================================*/

import { scrollMe } from './animation.scroll'

/*===================================================================================*/
/*	SPEED UP FOR MOBILE
/*===================================================================================*/
$(document).ready(function () {
    $('.speedUp main img, .speedUp main .lazy-bg, .lazy-wistia').unveil(350, function () {
        $(this).load(function () {
            this.style.opacity = 1
        })
    })
    $('video source').each(function () {
        var sourceFile = $(this).attr('data-src')
        if (sourceFile != undefined) {
            $(this).attr('src', sourceFile)
            var video = this.parentElement
            if ($(this).parent('video').prop('autoplay')) {
                if ($(video).is(':visible')) {
                    video.load()
                    video.play()
                }
            }
        }
    })
})

$(document).ready(function () {
    $('.tabs.tabs-reasons').easytabs({
        cycle: 1500,
    })

    $('.tabs.tabs-top, .tabs.tabs-circle-top, .tabs.tabs-2-big-top, .tabs.tabs-side').easytabs({
        animationSpeed: 200,
        updateHash: false,
    })
})

$(document).ready(function () {
    window.showCols()
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="copy"]').on('click', function () {
        var txt = $($(this).data('src')).text()
        $($(this).data('src')).after('<textarea></textarea>')
        var text = $($(this).data('src')).nextAll('textarea')
        text.val(txt)
        text[0].select()
        document.execCommand('copy')
        text.remove()
    })
})
$(window).bind('hashchange', function (e) {
    setTimeout(function () {
        window.showCols()
    }, 100)
    $('[data-toggle="tooltip"]').tooltip()
})

/*===================================================================================*/
/* ACCORDION (FOR ISOTOPE HEIGHT CALCULATION) */
/*===================================================================================*/

$(document).ready(function () {
    if ($('.panel-group .portfolio').length > 0) {
        $('.panel-group .collapse.in').collapse({
            toggle: true,
        })
    }
})

/*===================================================================================*/
/* DISABLED ON SUBMIT */
/*===================================================================================*/
$(document).ready(function () {
    $('form[data-disabled="submit"]').on('submit', function (ev) {
        var el = $(this)
        if (el.find('input.error').length == 0) {
            el.find('button[type="submit"]').prop('disabled', true).addClass('disabled')
            el.find('input[type="submit"]').prop('disabled', true).addClass('disabled')
        }
    })
})

/*===================================================================================*/
/* CONVERTING iOS SAFARI VIEWPORT UNITS (BUGGY) INTO PIXELS */
/*===================================================================================*/
$(document).ready(function () {
    window.viewportUnitsBuggyfill.init()
})

/*===================================================================================*/
/* OLD WEBSITE */
/*===================================================================================*/

$(document).ready(function () {
    var id = $.cookie('SERVERID')
    if (id) {
        $.cookie('SERVERID', id, { expires: 365, path: '/' })
    }

    $('.old-site').on('click', function (ev) {
        ev.preventDefault()
        $.cookie('SERVERID', 'master', { expires: 365, path: '/' })
        window.location.reload()
    })
})

/*===================================================================================*/
/* TOOLTIP */
/*===================================================================================*/

$(document).ready(function () {
    if ($('[rel=tooltip]').length) {
        $('[rel=tooltip]').tooltip()
    }
})

/*===================================================================================*/
/* OS - CLASS ADDED */
/*===================================================================================*/
if (navigator.userAgent.indexOf('Mac') >= 0) {
    $('body').addClass('mac')
} else {
    $('body').addClass('windows')
}

/*===================================================================================*/
/* IMAGE HOVER */
/*===================================================================================*/

$(document).ready(function () {
    $('.icon-overlay a').not('.ebook').not('.blog-author').prepend('<span class="icn-more"></span>')
    if ($('#get-ready').length > 0) {
        $(window).scroll(function () {
            if (window.window.scrollY > 300) {
                $('#get-ready').fadeIn(200)
            } else {
                $('#get-ready').fadeOut(200)
            }
        })
    }
    if ($('.buynow-link').length > 0) {
        $('.buynow-link a').attr('href', $('#hero a').attr('href'))
    }

    $('.services-link,#get-ready').on('click', function (ev) {
        ev.preventDefault()
        var scroll =
            $('#contact-campaigns .h1:first').offset().top -
            ($('.navbar-collapse.collapse.stuck').css('position') == 'fixed' ? 70 : 20)
        $('html, body').animate(
            {
                scrollTop: scroll + 'px',
            },
            {
                duration: 1000,
                easing: 'easeInOutCubic',
            }
        )
    })
})

/*===================================================================================*/
/* CONTENT BLOCK */
/*===================================================================================*/
$(function () {
    if ($('#generic_id').length == 1) {
        var order_id = getParameterByName('generic_id', window.location.href)
        $('#generic_id').val(order_id)
    }

    if ($('#referral_type').length == 1) {
        var type = getParameterByName('referral_type', window.location.href)
        $('#referral_type').val(type)
    }
})

/*===================================================================================*/
/* MODALS */
/*===================================================================================*/

$('.modal').on('hidden.bs.modal', function () {
    $('.video-container iframe').attr('src', $('.video-container iframe').attr('src'))
})

/*===================================================================================*/
/* DATA REL */
/*===================================================================================*/

$(document).ready(function () {
    $('a[data-rel]').each(function () {
        $(this).attr('rel', $(this).data('rel'))
    })
})

/*===================================================================================*/
/* Referral Code */
/*===================================================================================*/
$(document).ready(function () {
    $('.sharepop').click(function () {
        var left = screen.width / 2 - 550 / 2,
            top = screen.height / 2 - 450 / 2,
            NWin = window.open($(this).prop('href'), '', 'height=450,width=550,top=' + top + ',left=' + left)
        if (window.focus) {
            NWin.focus()
        }
        return false
    })
})

/*===================================================================================*/
/* Service layout section classes */
/*===================================================================================*/
$(document).ready(function () {
    var $blocks = $('.block-content section:gt(0)')
    $blocks.filter(':even').removeClass('light-bg')
    $blocks.filter(':odd').addClass('light-bg')
    $('.block-content #hero a[href="#contact-campaigns"]').on('click', function (ev) {
        ev.preventDefault()
        if ($(window).width() > 1024) {
            var navbarHeight = 45
        } else {
            var navbarHeight = 0
        }

        if ($(this).attr('data-anchor-offset') !== undefined) {
            var anchorOffset = $(this).attr('data-anchor-offset')
        } else {
            var anchorOffset = 0
        }

        $('html, body').animate(
            {
                scrollTop: $($(this).attr('href')).offset().top - navbarHeight - anchorOffset + 'px',
            },
            {
                duration: 1000,
                easing: 'easeInOutCubic',
            }
        )
    })
})

$('.sign-up-switch').on('click', function (ev) {
    ev.preventDefault()
    $('.inner.no-hero').toggleClass('hide')
})

// Download button
$('#download-button').click(function () {
    window.location.href = $('#download-button').data('download-link')
})

/*===================================================================================*/
/* Grow Modal on load in StartRIGHT page */
/*===================================================================================*/
if ($('#grow-modal').length > 0 && !window.location.href.includes('utm_campaign=grow_trilogy')) {
    $('#grow-modal').modal('show')
    $('#grow-submit').on('click', function () {
        $('#grow-success').removeClass('hidden')
    })
}

/*===================================================================================*/
/* Exit Intent Tier 1 and 2 Modal
/*===================================================================================*/
// Initialise
if ($('#modal-exit').length > 0) {
    const removeExitIntent = exitIntent({
        threshold: 15,
        maxDisplays: 1,
        eventThrottle: 100,
        onExitIntent: function () {
            if ($('modal-backdrop.fade.in').length == 0) {
                $('#modal-exit').modal('show')
            }
        },
    })
}

/*===================================================================================*/
/* Featured Product and Service Google Analytics
/*===================================================================================*/

$(document).ready(function () {
    if ($('.featured-product').length > 0) {
        $('.featured-product').on('click', function (e) {
            e.stopPropagation()
            const productLabel = e.target.className
            gtag('event', 'clicked featured product in megamenu', {
                event_category: 'megamenu',
                event_label: productLabel,
            })
        })
    }
    if ($('.featured-service').length > 0) {
        $('.featured-service').on('click', function (e) {
            e.stopPropagation()
            const serviceLabel = e.target.className
            gtag('event', 'clicked featured service in megamenu', {
                event_category: 'megamenu',
                event_label: serviceLabel,
            })
        })
    }
})

$(document).ready(function () {
    $('.checkout-click').each(function () {
        var label = $(this).data('e-label')
        $('a', this).on('click', function () {
            var params = {
                event_category: 'cart',
                event_label: label,
            }
            gtag('event', 'clicked button on checkout page', params)
        })
    })
})

$(document).ready(function () {
    $('.trackable').each(function () {
        var event = $(this).data('event')
        var name = $(this).data('name')
        $('a', this).on('click', function () {
            var params = {
                event_category: 'test_mode_' + name,
                event_label: $(this).attr('href'),
            }
            gtag('event', event, params)
        })
    })
})

$(document).ready(function () {
    $(window).on('message', function (e) {
        if (
            e.originalEvent.data.event &&
            e.originalEvent.data.event.indexOf('calendly') === 0 &&
            e.originalEvent.origin === 'https://calendly.com'
        ) {
            var event_action = e.originalEvent.data.event.replace('calendly.', '')
            var params = {
                event_category: 'Calendly',
                event_label: window.location.pathname,
            }

            gtag('event', event_action, params)
        }
    })
})

/*===================================================================================*/
/* Interactive logic for StartRIGHT Steps page */
/*===================================================================================*/

$(document).ready(function () {
    $('.next-step').on('click', function (ev) {
        ev.preventDefault()
        var id = 1
        $('.map .svg-arrow.active').each((index, el) => {
            var c = parseInt($(el).attr('id').replace('svg-', ''))
            if (c > id) id = c
        })
        if (id == 8) {
            $('#svg-1').trigger('click')
        } else {
            $('#svg-' + (id + 1)).trigger('click')
        }
    })
    $('.svg-arrow').on('click', function (ev) {
        ev.preventDefault()

        var id = $(this).attr('id').replace('svg-', '')
        var prefix = ''
        if (id.length > 1) {
            prefix = '2'
            id = id.substring(1)
            $('#main-title').css('background-color', $('.info-step-2' + id).css('border-color'))
        }

        $('.svg-arrow', '.map' + prefix).attr('class', 'svg-arrow')
        var counter = 1
        while (counter <= id) {
            $('#svg-' + prefix + counter).attr('class', 'svg-arrow active')
            counter++
        }
        $('.block-info', '.info-sections' + prefix).addClass(
            'hidden-xs hidden-sm hidden-md hidden-lg  animated fadeInUp'
        )
        $('.info-step-' + prefix + id, '.info-sections' + prefix).removeClass(
            'hidden-xs hidden-sm hidden-md hidden-lg  animated fadeInUp'
        )
        $('.active-title', '.info-sections' + prefix).addClass('hide')
        if (
            (window.location.hash == '' && id == 1) ||
            (window.location.hash != '' && window.location.hash == '#step-' + id)
        ) {
            $('.info-step-' + prefix + id)
                .prev()
                .removeClass('hide')
        }
        window.showCols()
    })
    if (window.location.hash != '' && $('#sc-steps').length > 0) {
        var id = window.location.hash.replace('#step-', '')
        $('.header-info, .toggle-step').addClass('hidden')
        $('.header-info-' + id + ', .toggle-step-' + id).removeClass('hidden')
        if (all_steps_survey_config['step_' + id] != null) {
            askNicelyConversation(all_steps_survey_config['step_' + id])
        }

        $('#svg-2' + id).trigger('click')
        if (id > 4) {
            $('.switchable').toggleClass('hidden')
        }

        if (parseInt(id) == 8) {
            $('.wheel-section.lifetime').toggleClass('hidden')
        } else {
            $('#svg-' + (parseInt(id) + 1)).trigger('click')
        }

        $('.svg-arrow', '.map2')
            .off('click')
            .on('click', function () {
                return false
            })
    } else {
        $('#svg-2').trigger('click')
    }
})
$(window).on('load', function () {
    if (window.location.hash != '' && $('#sc-steps').length > 0) {
        var id = window.location.hash.replace('#step-', '')
        if ($(window).width() <= 1024) {
            scrollMe($('.info-step-' + id))
        }
    }
})
/* Fix for Smart phone numbers  */
function phoneReplacement() {
    $('.contact-phone-en, .contact-phone-es, .contact-phone-billing').each(function () {
        var number = $(this).text()
        number = number.replace(/\D+/g, '')
        var p = $(this).parent()
        if (p.is('a')) {
            p.attr('href', 'tel:' + number)
        } else if (p.is('button')) {
            p.on('click', function () {
                window.location.href = 'tel:' + number
            })
        } else {
            $(this).on('click', function () {
                window.location.href = 'tel:' + number
            })
        }
    })
}

$(document).ready(function () {
    setTimeout(phoneReplacement, 1000)
})

$(document).ready(function () {
    /* /start-here */
    $('#main_trigger button').on('click', function () {
        //get type,
        var type = $('input[name="type"]:checked').val()
        if (type) {
            if ($('#question-set-' + type).length > 0) {
                $('#question-set-' + type)
                    .removeClass('hide')
                    .next('.well-small')
                    .removeClass('hide')
                $('#main_trigger').addClass('hide')
                $('#start_here_main').addClass('hide')
            }
        } else {
            alert('Please select if you are Church or Minister')
        }
        //decide
    })
})
// For Modal Videos Exit intent
$(document).ready(function () {
    $('#get_a_call').on('change', function () {
        var form = $(this).parents('form')
        var ref = $('input[name="referring_url"]', form)
        if (this.checked) {
            ref.val(ref.val().substring(0, ref.val().length - 1) + '1')
        } else {
            ref.val(ref.val().substring(0, ref.val().length - 1) + '2')
        }
    })
})

// Disclaimer
$(document).ready(function () {
    var cookie_ack = $.cookie('cookie_ack')
    if (cookie_ack != 'agree') {
        $('#disclaimer').removeClass('hidden')
        $('#disclaimer .btn').on('click', function (ev) {
            ev.preventDefault()
            $('#disclaimer').addClass('hidden')
            $.cookie('cookie_ack', 'agree', { expires: 365, path: '/' })
        })
    }
})
function isSpanish() {
    return window.location.href.indexOf('espanol') >= 0
}
function isWorkingHour(now) {
    var easternTimeString = now.toLocaleString('en-US', { timeZone: 'America/New_York' })
    var nowInEasternTime = new Date(Date.parse(easternTimeString))

    var holidays = [
        '2023-1-2',
        '2023-4-7',
        '2023-5-29',
        '2023-6-19',
        '2023-7-4',
        '2023-9-4',
        '2023-11-23',
        '2023-11-24',
        '2023-12-22',
        '2023-12-25',
    ]
    var today =
        nowInEasternTime.getFullYear() + '-' + (nowInEasternTime.getMonth() + 1) + '-' + nowInEasternTime.getDate()
    if (holidays.includes(today)) {
        return false
    }

    var hours = nowInEasternTime.getHours()
    var minutes = nowInEasternTime.getMinutes()
    var day = nowInEasternTime.getDay()
    var workDay = day <= 5 && day > 0
    var openWorkHours = ((hours >= 8 && minutes >= 30) || hours >= 9) && hours < 19
    if (isSpanish()) {
        openWorkHours = hours >= 9 && hours < 19
    }
    return workDay && openWorkHours
}

$(document).ready(function () {
    if (!isWorkingHour(new Date())) {
        $('.business-time').toggleClass('hidden')
    }

    if ($('.countdown').length > 0) {
        var countDownDate = new Date($('.countdown').data('date')).getTime()
        var x = setInterval(function () {
            var now = new Date().getTime()
            var distance = countDownDate - now
            var days = Math.floor(distance / (1000 * 60 * 60 * 24))
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            var seconds = Math.floor((distance % (1000 * 60)) / 1000)

            $('#countdown-days').text(days)
            $('#countdown-hours').text(hours)
            $('#countdown-minutes').text(minutes)
            $('#countdown-seconds').text(seconds)

            if (distance < 0) {
                clearInterval(x)
            }
        }, 1000)
    }
})
$('[data-toggle-cart]').on('click', function () {
    $('.cart-modal').toggleClass('in')
})

$('[data-calendar]').on('click', function (ev) {
    ev.preventDefault()

    var url = new URL($(this).attr('href'))
    url.searchParams.append('embed', 'true')
    url = url.toString()

    var style = `
        .meetings-iframe-container {
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            z-index: 10000000;
            background: rgba(0,0,0,.6);
        }

        @media(min-width: 850px) {
            .meetings-iframe-container iframe {
                max-width: 80% !important;
            }
        }

        .meetings-iframe-container-close-wrapper {
            width: 100%;
        }

        .meetings-iframe-container-close-button {
            width: 50px;
            height: 50px;
            margin: auto;
            border: 1px solid white;
            border-radius: 50%;
            background: rgba(0,0,0,.6);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            cursor: pointer;
        }

        .meetings-iframe-container-close-button:hover {
            background: rgba(0,0,0,.8);
        }
    `

    // append style
    $('<style />').text(style).appendTo('body')

    // append widget
    $('body')
        .append(`<div class="meetings-iframe-container" data-src="${url}" />`)
        .append('<script src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"></script>')

    // close button
    $('<div class="meetings-iframe-container-close-wrapper"/>')
        .html('<div class="meetings-iframe-container-close-button">&times;</div>')
        .appendTo('.meetings-iframe-container')

    // prevent scroll on body
    const bodyOverflowBefore = $('body').css('overflow')
    $('body').css('overflow', 'hidden')

    // close on click outside
    $('.meetings-iframe-container').click(function (e) {
        $(this).remove()
        $('body').css('overflow', bodyOverflowBefore)
    })
})
