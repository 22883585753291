/*===================================================================================*/
/* CAMPAIGN PROM */
/*===================================================================================*/

$(document).ready(function () {
    $('.prom i').on('click', function (ev) {
        ev.preventDefault()
        $('.prom').removeClass('small')
        $.cookie('PromShowSignup', 0, { expires: 365, path: '/' })
    })
    $('.prom span a').on('click', function (ev) {
        ev.preventDefault()
        $('.prom').addClass('small')
        $.cookie('PromShowSignup', 1, { expires: 365, path: '/' })
    })
    if ($.cookie('PromShowSignup') == 1) {
        $('.prom span a').click()
    }
})

function getParameterByName(name, url) {
    if (!url) url = window.location.href
    name = name.replace(/[\[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

/*===================================================================================*/
/* CAMPAIGN HOVER */
/*===================================================================================*/

$(document).ready(function () {
    var vh = $('.notification-hover')
    setTimeout(function () {
        vh.addClass('shown')
        setTimeout(function () {
            vh.addClass('bounce animated')
        }, 300)
        setTimeout(function () {
            vh.removeClass('bounce animated')
        }, 1000)
    }, 1000)
    $('.notification-hover .close, .notification-hover a').on('click', function (ev) {
        vh.addClass('bounce animated').removeClass('shown')
        setTimeout(function () {
            $('.hover-img').addClass('shown')
        }, 800)
    })
    if (
        $('body').hasClass('no-modal') ||
        $.cookie('utm_campaign') == 'onboarding_automation' ||
        window.location.pathname.indexOf('campaigns/confirmation') >= 0
    )
        return

    var hover = $('#modal-hover'),
        code = hover.data('code')
    var e = $('.hover-show')
    hover
        .on('hide.bs.modal', function () {
            e.fadeIn()
            $.cookie(code + 'ShowSignup', 0, { expires: 365, path: '/' })
        })
        .on('show.bs.modal', function () {
            $.cookie(code + 'ShowSignup', 1, { expires: 365, path: '/' })
        })

    if (getParameterByName('utm_source') && getParameterByName('utm_source').toUpperCase().indexOf('NEWSLETTER') >= 0) {
        $.cookie(code + 'ShowSignup', 0)
    }

    if ($.cookie(code + 'ShowSignup') == 1 || $.cookie(code + 'ShowSignup') == null) {
        hover.data('showing', false)
        if ($('#conference').length > 0 || $('#conferences').length > 0) return false
        if (window.showTakeOver !== undefined && window.showTakeOver !== false) {
            setTimeout(function () {
                e.fadeOut()
                hover.modal('show')
            }, 10 * 1000)
        }
    }
    e.on('click', function (ev) {
        ev.preventDefault()
        e.fadeOut()
        hover.modal('show')
    })
})

/*===================================================================================*/
/* CAMPAIGNS - EXTRA FIELD #campaigns-contact-form */
/*===================================================================================*/

function appendTextInputField(item, sectionForAppending) {
    const rowDiv = `<div id="extra-field-parent-row" class="row"${
        item.row.length > 1 ? ' style="position: relative"' : null
    }></div>`
    sectionForAppending.append(rowDiv)

    for (let column of item.row) {
        $('#extra-field-parent-row', sectionForAppending).append(
            `<div class="${column.colWidth}"><div class="control-group"><div class="controls">` +
                `<input type="${item.type}" name="${column.name}" id="${column.name}" value="" placeholder="${
                    column.placeholder
                }" class="${column.class}" aria-required="${column.required}"${
                    column.required ? ' data-msg-required="Please enter your "' + column.name : null
                }>` +
                '</div></div></div>'
        )
    }
}

$(document).ready(function () {
    var efDiv = $(
        '#campaigns-contact-form div.extra-fields, #video-course-contact-form div.extra-fields, .campaigns-contact-form div.extra-fields'
    )
    var extra = efDiv.data('json')
    efDiv.each(function () {
        var section = $(this)
        if (typeof extra !== 'undefined' && extra.length > 0) {
            var html = ''
            for (index in extra) {
                var item = extra[index]

                if (item.hasOwnProperty('type')) {
                    appendTextInputField(item, section)
                } else {
                    section.append('<div id="control-group-' + index + '" class="control-group text-left"></div>')
                    $('#control-group-' + index, section).append(
                        '<div class="controls"><label class="extra-field" data-name="question_' +
                            index +
                            '">' +
                            item.q +
                            '</label></div>'
                    )

                    html = ''
                    var answerLength = item.a.length
                    if (answerLength > 5) {
                        html +=
                            '<select class="extra-field"  data-name="answer_' +
                            index +
                            '" name="answer_' +
                            index +
                            '" data-index="' +
                            index +
                            '"><option value="N/A"> - select - </option>'
                    }
                    for (var i = 0; i < answerLength; i++) {
                        var key = Object.keys(item.a[i])[0]
                        if (answerLength > 5) {
                            html += '<option value="' + item.a[i][key] + '">' + key + '</option>'
                        } else {
                            html +=
                                '<div class="radio"><label data-name="answer_' +
                                index +
                                '"><input type="radio" data-index="' +
                                index +
                                '" name="answer_' +
                                index +
                                '" value="' +
                                key +
                                '" >' +
                                item.a[i][key] +
                                '</label></div>'
                        }
                    }
                    if (answerLength > 5) {
                        html += '</select></label>'
                    }
                    $('#control-group-' + index + ' label', section).after(html)
                    ;(function ($, index, section) {
                        $('input[name="answer_' + index + '"]', section).on('click', function () {
                            var val = $(this).val()
                            //Check for Tier
                            $.each(extra[index].a, function (i) {
                                if (this.hasOwnProperty(val)) {
                                    if (this.hasOwnProperty('tier')) {
                                        $('input[name="tier"]', section.parents('form')).val(extra[index].a[i].tier)
                                    }
                                    return false
                                }
                            })
                            $(this).parents('.controls').find('.radio > label').removeClass('extra-field')
                            $(this).parent('label').addClass('extra-field')
                            $(this).parents('.controls').find('label.text-danger').remove()
                        })
                    })($, index, section)

                    if (item.e !== undefined) {
                        $('input[name="answer_' + index + '"]', section).rules('add', {
                            required: true,
                            messages: {
                                required: item.e,
                            },
                        })
                    }
                }
            }
        }
    })
})
